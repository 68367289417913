// components/Gtm.js
import Script from 'next/script';
import Head from 'next/head';

const Gtm = () => {
    const isProduction = process.env.NODE_ENV === 'production';

    if (!isProduction) {
        return null; // Don't load GTM in non-production environments
    }
  const gtmContainerId = 'GTM-PLP4XZ5';

  return (
    <>
    <Head>
      <script
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${gtmContainerId}');
          `,
        }}
      />
    </Head>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${gtmContainerId}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </>
  );
};

export default Gtm;
